(function () {'use strict';}());

angular.module('app')
  .controller('SelectionController', function ($scope,SettingsService,ContainerService,$timeout) {
  	var trace = angular.trace;

	$scope.$watch("currentPage",onPageChange); 
	$scope.currentSelections = {};


	$scope.toggleCard = function(card){
		if($scope.currentSelections[card]) $scope.currentSelections[card] = false;
		else $scope.currentSelections[card] = true;
	};


	$scope.choose = function(choose){
		if(choose) 
		{
		 	$scope.mode = "choose";
		 	return;
		}

		if($scope.mode === "default"){
			$scope.preferences.selections = $scope.preferences.game.cards.concat();
		}
		else 
		{
			$scope.preferences.selections = [];
			for(var i = 0, count = $scope.preferences.game.cards.length; i < count; i++){
				var card = $scope.preferences.game.cards[i];
				if($scope.currentSelections[card.id]) $scope.preferences.selections.push(card);
			}


			if(!$scope.preferences.selections.length) return;


		}
		$scope.preferences.selections.sort(randomize);
		$scope.setCurrentPage($scope.PAGE_CALLER);
		$scope.mode = "default";
	};

	function randomize(a,b){
		return Math.random() > 0.5 ? 1 : -1;
	}

  	function initialize(){
  		$scope.mode = "default";
  		$scope.currentSelections = {};
  	}

	function onPageChange(value){
		if(value === $scope.PAGE_SELECTION) initialize();
	}
  });
