(function () {'use strict';}());

angular.module('app')
  .controller('RegistrationController', function ($scope,$document,SettingsService,ContainerService,$timeout) {
  	var trace = angular.trace;
    $scope.processing = false;
    $scope.registration = {firstname:null,lastname:null,email:null,occupation:"no-selection",reference:null};
    
    $scope.onSubmit = function(){
      if(!$scope.registration.firstname ||
          !$scope.registration.lastname ||
          !$scope.registration.email ||
          !$scope.registration.occupation ||
          $scope.registration.occupation === "no-selection" ||
          !$scope.registration.reference) return alert("You must complete all registration fields before continuing.");
      $scope.processing = true;
      ContainerService.submitRegistration($scope.registration).then(onSubmissionComplete);
    };

    function onSubmissionComplete(){
      var expiration_date = new Date();
      var cookie = "";
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);
      cookie = "registered=true; path=/; expires=" + expiration_date.toGMTString();
      $document.get(0).cookie = cookie;
      $scope.setRegistration(true);
      $scope.setCurrentPage($scope.PAGE_LANGUAGE);
      $scope.processing = false;
    }

  });
