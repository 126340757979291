(function () {'use strict';}());
angular.module("app").directive('stagesMenu', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/menu.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        
      }  
    };
  });