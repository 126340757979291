(function () {'use strict';}());

angular.module('app')
  .controller('MenuController', function ($scope,$document,SettingsService,ContainerService,$timeout) {
  	var trace = angular.trace;

  		$scope.items = [];
      $scope.currentMenuItem = null;
      $scope.expanded = true;

  		if($scope.menu) onMenuDefined($scope.menu);
  		else $scope.$watch("menu",onMenuDefined);

      $scope.closeMenu = function ($event){
        $event.stopImmediatePropagation();
        $scope.currentMenuItem = null;
        $scope.hideMenu();
        $scope.expanded = true;
      };

  		$scope.select = function($event,link){
        $event.stopImmediatePropagation();
  			$scope.currentMenuItem = link;
        $scope.expanded = false;
        var reg = /^app:(.*?)$/gim;
        var match = reg.exec(link.content);

        if(match) {
          $scope.setReturnPage($scope.currentPage);
          $scope[match[1]]();
          $scope.closeMenu($event);
        }
        
        if($scope.connectivity === true && link.url) ContainerService.launchURL(link.url);
  		};

      $scope.menuClick = function(){
        $scope.expanded = !$scope.expanded;
      };

  		function onMenuDefined(data){
  			if(!data) return;
  			$scope.items = data;
  		}
  });
