(function () {'use strict';}());
angular.module("app").directive('registrationView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/registration.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $timeout(function(){
        	$("input,select").blur(function(){
        		window.scrollTo(0,0);
        	});
        },0);
      }  
    };
  });