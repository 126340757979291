(function () {'use strict';}());

angular.module('app')
  .controller('AppController', function ($scope,$window,ContainerService,$timeout) {
  	var trace = angular.trace;
    $scope.registered = document.cookie.replace(/(?:(?:^|.*;\s*)registered\s*\=\s*([^;]*).*$)|^.*$/, "$1") === "true";
    $scope.PAGE_HOME = 0;
    $scope.PAGE_REGISTRATION = 2;
    $scope.PAGE_LANGUAGE = 3;
    $scope.PAGE_GAMES = 4;
    $scope.PAGE_GOTGAME = 5;
    $scope.PAGE_SELECTION = 6;
    $scope.PAGE_CALLER = 7;
    $scope.PAGE_ADULTS = 8;
    $scope.PAGE_BINGO = 9;
    $scope.PAGE_STORE = 10;

    $scope.currentPage = null;
    $scope.currentLink = null;
    $scope.ready = false;
    $scope.lastPage = null;
    $scope.preferences = {language:null,game:null,selections:[]};

    $scope.menu = null;
    $scope.showMenu = false;

    angular.lbapp = $scope;

    $scope.getPagePosition = function(page){
        if($scope.currentPage === null) return "disabled";
        if(page === $scope.currentPage) return "foreground";
        return "background";
    };

    $scope.setCurrentPage = function(page,link,returnPage,callback){
        $scope.currentPage =  page;
        $scope.currentLink = link;
        $scope.returnPage = returnPage;
        $scope.currentCallback = callback;
        window.scrollTo(0,0);
    };

    $scope.setRegistration = function(value){
        $scope.registered = value;
    };

    $scope.languageSelect = function(language){
        $scope.setCurrentPage($scope.PAGE_GAMES);
    };
    $scope.gameSelect = function(game){
        $scope.preferences.game = game;
        $scope.setCurrentPage($scope.PAGE_GOTGAME);
    };

    $scope.openMenu = function(allow) {
        if(allow === true){
            $scope.showMenu = true;
        }
        else {
            angular.lbapp.connectivity = setConnectivity;
            ContainerService.checkConnectivity();
            $scope.setCurrentPage($scope.PAGE_ADULTS,null,$scope.currentPage,$scope.openMenu);
        }
    };

    $scope.more = function(){
      //  $window.open("http://www.stageslearning.com/collections","width=1024,height=768");
      ContainerService.launchURL("http://www.stageslearning.com/collections?utm_source=bingo_caller_app&utm_medium=mobile_app&campaign=bingo_caller_app");
    };

    $scope.applePay = function(){
        $scope.setCurrentPage($scope.PAGE_STORE,null,$scope.currentPage);
    };
    $scope.applePayCallback = function(message){
        trace("dismssing and returning to:",$scope.returnPage);
       $scope.$apply(function(){$scope.setCurrentPage($scope.returnPage);});
    };

    $scope.hideMenu = function(){
        $scope.showMenu = false;
    };

    $scope.setReturnPage = function(page){
        $scope.returnPage = page;
    };

    function setConnectivity(value){
        $scope.connectivity = value ? true : false;
    }

    function onFetchListing(data){
        $scope.languages = ContainerService.langs;
        $scope.games = ContainerService.packs;
        $scope.ready = true;
        $scope.currentPage = $scope.PAGE_HOME;

        ContainerService.fetchMenu().then(onFetchMenu);

        //TEMP
        //emulate();
    }

    function onFetchMenu(data){
        $scope.menu = ContainerService.menu;
    }

    function emulate(){
        $scope.currentPage = $scope.PAGE_LANGUAGE;
        $scope.connectivity = true;
       // $scope.preferences.game = ContainerService.packs[1];
       // $scope.preferences.language = ContainerService.langs[5];
    }

    function prep(){
        $timeout(onFetchListing,4000);
    }


    (function(){
        ContainerService.fetchListing().then(prep);
    }());

  });
