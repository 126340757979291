(function () {'use strict';}());
angular.module('app').service('HTMLService', function($http)
{
	var trace = angular.trace;
	var self = {};
	
	self.fetchListing = function(){
		return $http.post('php/scripts/SettingsService.php',{request:"listing"}).success(onFetchListing);
	};

	self.submitRegistration = function(data){
		return $http.post('php/scripts/RegistrationService.php',data);
	};

	function onFetchListing(data){
		var cards = data.cards;
		cards.sort(sortCards);

		var packs = {};
		self.packs = [];
		self.cards = cards;
		self.langs = [{label:"English",abbr:'en'},{label:"Spanish",abbr:'sp'},{label:"French",abbr:'fr'},{label:"German",abbr:'gr'},{label:"Italian",abbr:"it"},{label:"Russian",abbr:"ru"}];

		for(var i = 0, count = cards.length; i < count; i++){
			var card = cards[i];
			if(!packs[card.pack]) 
			{
				packs[card.pack] = {label:card.pack,cards:[]};
				self.packs.push(packs[card.pack]);
			}
			card.label = {en:card.label,fr:card.fr,sp:card.sp};
			card.audio = "audio/"+card.label.en.toLowerCase().replace(/\s+/gim,"-")+"/word.mp3";
			packs[card.pack].cards.push(card);
			delete card.fr;
			delete card.sp;
		}

		trace(self);
	}

	function sortCards(a,b){
		if(a.pack > b.pack) return 1;
		if(a.pack < b.pack) return -1;
		if(a.label > b.label) return 1;
		if(a.label < b.label) return -1;
	}

	return self;
});