(function () {'use strict';}());

angular.module('app')
  .controller('AdultsController', function ($scope,$window,SettingsService,ContainerService,$timeout) {
  	var trace = angular.trace;

  	$scope.currentChallenge = null;
  	$scope.userInput = null;
  	$scope.$watch("currentPage",onPageChange);

  	$scope.enter = function(i){
  		if($scope.userInput === null) $scope.userInput = "";
  		if(i !== null) $scope.userInput += i;
  		if($scope.userInput.toString() === $scope.currentAnswer.toString()) 
      {

        if($scope.currentCallback) {
          $scope.currentCallback(true);
          $scope.setCurrentPage($scope.returnPage);
          return;
        }

        ContainerService.launchURL($scope.currentLink);
        //$window.open($scope.currentLink,"width=1024,height=768");
        $scope.setCurrentPage($scope.returnPage);
      }
  	};

    $scope.back = function(){
      $scope.setCurrentPage($scope.returnPage);
    };

  	function initialize(){
  		var variable1 = getRandomInt(10,50);
  		var variable2 = getRandomInt(4,9);
  		$scope.userInput = null;
  		$scope.currentChallenge = variable1 + " + " + variable2 + " = ?";
  		$scope.currentAnswer = variable1 + variable2;
  	}

  	function getRandomInt(min, max) {
	    return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	function onPageChange(value){
		if(value === $scope.PAGE_ADULTS) initialize();
	}
  });
