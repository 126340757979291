(function () {'use strict';}());

angular.module('app')
  .controller('CallerController', function ($scope,SettingsService,ContainerService,$timeout,$document) {
  	var trace = angular.trace;
    $scope.sequence = [];
    $scope.called = [];
    $scope.currentIndex = -1;
    $scope.currentAudio = null;

    $scope.$watch("currentPage",onPageChange); 


    $scope.nextCard = function(){
      if($scope.currentIndex >= $scope.sequence.length - 1) return $scope.endGame();
      $scope.currentIndex ++;
      $scope.currentCard = $scope.sequence[$scope.currentIndex];
      if($scope.called.indexOf($scope.currentCard) === -1) $scope.called.push($scope.currentCard);
      $timeout($scope.playAudio,500);
    };

    $scope.previousCard = function(){
      if($scope.currentIndex <= 0) return;
      $scope.currentIndex --;
      $scope.currentCard = $scope.sequence[$scope.currentIndex];
      $timeout($scope.playAudio,500);
    };

    $scope.endGame = function(){
      $scope.finished = true;
     // $scope.mode = "finished";
      $scope.setCurrentPage($scope.PAGE_BINGO);
      //$scope.setCurrentPage($scope.PAGE_GAMES);
    };

    $scope.bingo = function(){
        $scope.setCurrentPage($scope.PAGE_BINGO);
        var audio = $document.find("#sfx-audio").get(0);
        audio.load();
        audio.play();
    };

    $scope.toggleCalledWords =  function(){
      if($scope.mode === "default" && !$scope.finished) $scope.mode = "listing";
      else if(!$scope.finished) $scope.mode = "default";
      else if($scope.finished && $scope.mode === "listing") $scope.mode = "finished";
      else $scope.mode = "listing";
    };

    $scope.playAudio = function(card){
      var suffix = "audio_"+$scope.preferences.language.abbr;
      if($scope.currentCard.multi){
        $scope.currentAudio = card ? card[suffix]: $scope.currentCard.cards[0][suffix];
      }
      else{
        $scope.currentAudio = card ? card[suffix]: $scope.currentCard[suffix];
      }
      
      $timeout(function(){
        var audio = $document.find("#card-audio").get(0);
        audio.load();
        if((card || $scope.currentCard).multi){
          var language = $scope.preferences.language.abbr;
          var label1 = (card || $scope.currentCard).cards[0].label[language].replace(/\s(fe)?male$/gim,"").replace(/\s+/gim,"").toLowerCase();
          var label2 = (card || $scope.currentCard).cards[1].label[language].replace(/\s(fe)?male$/gim,"").replace(/\s+/gim,"").toLowerCase();
          if(label1 === label2) return;
          audio.addEventListener("ended",playAudio2);
        }
      },0);
      
    };

    function playAudio2(){
      var audio = $document.find("#card-audio").get(0);
      var suffix = "audio_"+$scope.preferences.language.abbr;
      audio.removeEventListener("ended",playAudio2);
      $scope.currentAudio = null;//$scope.currentCard.cards[1][suffix];
      $timeout(function(){
        $scope.currentAudio = $scope.currentCard.cards[1][suffix];
        $timeout(function(){
          audio.load();
        },0);
      },0);
    }

    $scope.replay = function(){
      $scope.mode = "default";
      $scope.currentIndex = -1;
      $scope.called = [];
      $scope.finished = false;
      $scope.sequence =  $scope.preferences.selections.concat();
      $scope.sequence.sort(function(a,b){
        return Math.random() > 0.5 ? 1 : -1;
      });
      $scope.nextCard();
    };

    function initialize(){
    	$scope.mode = "default";
      $scope.currentIndex = -1;
      $scope.called = [];
      $scope.finished = false;
      $scope.sequence =  $scope.preferences.selections.concat();
      $scope.nextCard();
    }



	function onPageChange(value){
		if(value === $scope.PAGE_CALLER) initialize();
	}
  });
