(function () {'use strict';}());
var rtmapp = (function(self){

	angular.module('app', []).config(function($sceProvider){
		$sceProvider.enabled(false);
	});

	$(function() {
	    FastClick.attach(document.body);
	});

	  angular.trace = function (){
	    for(var i = 0, count = arguments.length; i < count; i++)
	    {
	      try {console.log(arguments[i]);}
	      catch (error){}
	    }
	  };


	return self;
}(rtmapp = rtmapp || {}));