(function () {'use strict';}());
angular.module('app').service('ContainerService', function($http,$window,IOSService,HTMLService)
{
	var trace = angular.trace;
	var self = {};
	
	//Platform detection
	var standalone = $window.navigator.standalone,
    userAgent = $window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test( userAgent ),
    ios = /iphone|ipod|ipad/.test( userAgent );
    self.ios = !standalone && !safari;
    self.ios = true;
    if(self.ios) return IOSService;
	return HTMLService;
});