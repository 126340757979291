(function () {'use strict';}());

angular.module('app')
  .controller('StoreController', function ($scope,SettingsService,ContainerService,$timeout,$window) {
  	var trace = angular.trace;
  	var products = {"animals":276677081,"careers":276705433,"everyday-objects":276676513,"foods":276698597,"transport":276701745,"set":444577400};

  	$scope.items = [];

	$scope.$watch("currentPage",onPageChange); 

	$scope.add = function(item){
    if(item.confirmed){
      item.qty = 0;
      item.confirmed = false;
    }
		else item.confirmed = true;
    updateCount();
	};
	$scope.pay = function(){

    trace("payment attempt");
		var cart = [];
		for(var i = 0, count = $scope.items.length; i < count; i++){
			var item = $scope.items[i];
			if(!item.qty || !item.confirmed) continue;
			cart.push({id:item.pid,qty:item.qty});
		}

		ContainerService.applePay(cart).then(onPaymentComplete);
	};
  $scope.isDisabled = function(){
    if($scope.selected) return false;
    return true;
  };

  $scope.exit = function(){
    $scope.setCurrentPage($scope.returnPage || $scope.PAGE_GOTGAME);
  };

  $scope.stages = function(){
    ContainerService.launchURL("http://www.stageslearning.com/collections/bingo-games?utm_source=bingo_caller_app&utm_medium=mobile_app&campaign=bingo_caller_app");
  };

	function onPaymentComplete(){
		
	}
  function updateCount(){
    $scope.selected = 0;
    for(var i = 0, count = $scope.items.length; i < count; i++){
      var item = $scope.items[i];
      if(item.qty) $scope.selected += item.qty;
    }
  }

  	function initialize(){
  		$scope.items = [];
  		$scope.featuredPack = $scope.preferences.game;

  		for(var i = 0, count = $scope.games.length; i < count; i++){
  			var game = $scope.games[i];
  			var id = game.label.toLowerCase().replace(/\s+/gim,"-");
  			var product = products[id];
  			if(!product) continue;
  			var item = {title:game.label + " Picture Bingo",
  									image:"images/product-"+id+".jpg", qty:0,
  									price:"$12.99",pid:product,data:game};

  			if(game === $scope.featuredPack) $scope.items.unshift(item);
  			else $scope.items.push(item);
  		}

  		$scope.items.push({title:"Picture Bingo 5 Game Set",
  									image:"images/product-set.jpg", qty:0,
  									price:"$12.99",pid:products.set});
  	}

	function onPageChange(value){
		if(value === $scope.PAGE_STORE) initialize();
	}
  });
