(function () {'use strict';}());

angular.module('app')
  .controller('GotGameController', function ($scope,$window,SettingsService,ContainerService,$timeout) {
  	var trace = angular.trace;

	$scope.$watch("currentPage",onPageChange); 

	$scope.hasCards = function(has){
		if(has) $scope.setCurrentPage($scope.PAGE_SELECTION);
		else $scope.mode = "buy";
	};

	$scope.buyCards = function(){
		//$window.open('http://www.stageslearning.com/collections/bingo-games',"width=1024,height=768");
	};
	$scope.printCards = function(){
		$window.open('http://www.stageslearning.com/pages/stages-learning-bingo-generator?utm_source=bingo_caller_app&utm_medium=mobile_app&campaign=bingo_caller_app',"width=1024,height=768");
	};
	
  	function initialize(){
  		$scope.mode = "default";
  	}

	function onPageChange(value){
		if(value === $scope.PAGE_GOTGAME) initialize();
	}
  });
